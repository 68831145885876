import React from "react"

import Layout from "../components/layout"
import Articles from "../components/Articles"
import SEO from "../components/seo"

import "../styles/modularscale.scss"

const ArticlePage = ({ data }) => (
  <Layout>
    <SEO title="Articles" />
    <Articles articles={data.allMarkdownRemark.edges} />
  </Layout>
)

export const query = graphql`
  query ArticlePage {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            description
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ArticlePage
